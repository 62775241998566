<template>
  <v-card>
    <v-app-bar dark color="primary">
      <v-icon large class="mr-3">person_outline</v-icon>
      <v-toolbar-title>User Activity</v-toolbar-title>
    </v-app-bar>

    <div class="pa-4">
      <v-alert :value="exportDisable" 
        colored-border
        dismissible
        color="primary lighten-1"
        border="top"
        icon="mdi-check"
        transition="scale-transition"
        elevation="2"
        prominent
      >
        Export started. Your file will be delivered to <b>{{ this.$store.state.userProfile.email }}</b>.
      </v-alert>

      <v-row>
        <v-col cols="6">
          <v-autocomplete
            label="Vendor Code"
            :items="projectReferralCodes"
            :item-text="(item) => `${item.name} - ${item.projectCode}`"
            item-value="projectCode"
            v-model="dataToSend.projectReferralCodes"
            v-validate="'required'"
            required
            multiple
            persistent-hint
            chips
            hint="Choose the vendor code and click select"
            @change="loadProjects(dataToSend.projectReferralCodes)"
          >
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-autocomplete
            :items="projects"
            :item-text="item => $languageProcessor.getEnText(item.name)"
            item-value="_id"
            autocomplete
            persistent-hint
            hint="Choose the project and click select"
            v-model="dataToSend.projectId"
            label="Choose Project"
            v-on:change="setProjectAndGetActivity"
          />
        </v-col>

        <v-col>
          <v-select v-if="dataToSend.projectId"
            :items="activities"
            :item-text="item => $languageProcessor.getEnText(item.name)"
            item-value="_id"
            multiple
            v-model="dataToSend.activityIds"
            label="Filter By Activity"
          />
        </v-col>
      </v-row>

      <!-- Switch -->
      <v-row>
        <v-col>
          <v-switch
            v-model="filterStartDate"
            :label="`Filter by Start Date`"
            color="primary"
          ></v-switch>
        </v-col>

        <v-col>
          <v-switch
            v-model="filterEndDate"
            :label="`Filter by End Date`"
            color="primary"
          ></v-switch>
        </v-col>

        <v-col>
          <v-menu v-if="filterStartDate"
            ref="startDateMenu"
            v-model="startDateMenu"
            :close-on-content-click="false"
            :return-value.sync="startDate"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dataToSend.startDate"
                label="Start Date"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              color="primary"
              no-title
              scrollable
              v-model="dataToSend.startDate"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$refs.startDateMenu.save(Date(startDate))"
              >OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col>
          <v-menu v-if="filterEndDate"
            ref="endDateMenu"
            v-model="endDateMenu"
            :close-on-content-click="false"
            :return-value.sync="endDate"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dataToSend.endDate"
                label="End Date"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              no-title
              color="primary"
              scrollable
              v-model="dataToSend.endDate"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$refs.endDateMenu.save(Date(endDate))"
              >OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select
            :items="[
              { key: 'I', label: 'Invalid' },
              { key: 'A', label: 'Active' },
              { key: 'T', label: 'Terminate' },
              { key: 'C', label: 'Complete' }
            ]"
            label="Activity Status"
            multiple
            deletable-chips
            v-model="dataToSend.activityStatuses"
            item-text="label"
            item-value="key"
            persistent-hint
            hint="Choose Activity Statuses"
          ></v-select>
        </v-col>

        <v-col>
          <v-select
            :items="[
              { key: 'True', value: true },
              { key: 'False', value: false },
            ]"
            label="Valid Status"
            deletable-chips
            v-model="dataToSend.validStatus"
            item-text="key"
            item-value="value"
            persistent-hint
            hint="Filter by Activity Validity"
            multiple
          />
        </v-col>
      </v-row>
      
      <v-row>
        <v-col>
          <v-btn :disabled="dataToSend.projectId == null"
            class="primary"
            @click="fetchUserActivity"
          >Load</v-btn>

          <v-btn :disabled="dataToSend.projectId == null"
            class="primary ml-4"
            @click="() => exportUserActivityToFile()"
          >Export</v-btn>

        </v-col>

        <v-col>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search Table"
            single-line
            hide-details
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-switch
            label="Exclude data in export"
            v-model="dataToSend.excludeActivityData"
            persistent-hint
            hint="Choose to include or exclude data in File Export"
          ></v-switch>
        </v-col>
      </v-row>

      <v-row >
        <v-col>
          <v-card-text>
            <ul>
              <li>UserId: Unique id of each respondent</li>
              <li>ProjectId: id of the project a respondent has been assigned</li>
              <li>Activities: Each project contains one or more than one activity.</li>
              <ol> 0 means respondent has not completed an activity</ol>
              <ol> 1 means respondent has completed an activity</ol>
              <ol>If the activity is recurring, then the value can be more then 1. Depending upon how many times a respondent has completed that activity</ol>
              <li>Status: It determines the status of the activity</li>
              <ol>A means the activity is in active state. Respondent has not completed it</ol>
              <ol>C means the activity is in complete state. Respondent has completed it</ol>
              <ol>If the activity is recurring, the status wont change to C, it will always be A</ol>
            </ul>
          </v-card-text>
        </v-col>
      </v-row>


    </div>
    
    <!-- Data Table -->
    <v-data-table v-if="dataTableReady"
      :headers="headers"
      :items="userActivity"
      :search="search"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <!-- <v-icon small class="mr-2" @click="$router.push({ path: `/mobile/userActivity/${item.userId}/${item.projectId}/edit` }) ">edit</v-icon>  -->
        <a :href="generateEditUrl(`/vendor/userActivity/${item.userId}/${item.projectId}/view`)" target="_blank">View</a>
      </template>
    </v-data-table>

  </v-card>
</template>

<script>
import DataServiceVD from "../services/dataServiceVD.js";

const dataServiceVD = new DataServiceVD();

export default {
  data() {
    return {
      searchCriteria: {},
      projectReferralCodes: [],
      
      exportDisable: false,
      search: "",
      projects: [],
      activities: [],
      userActivity: [],
      headers: [
        { text: "UserId", value: "userId", sortable: true },
        { text: "ProjectId", value: "projectId", sortable: true },
        { text: "Activities", value: "submissions", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Action", value: "actions", sortable: false },
      ],
      startDate: null,
      endDate: null,
      startDateMenu: false,
      endDateMenu: false,
      filterStartDate: false,
      filterEndDate: false,
      dataToSend: {
        projectReferralCodes: [],
        projectId: null,
        startDate: undefined,
        endDate: undefined,
        activityStatuses: [ "I", "A", "T", "C" ],
        activityIds: [],
        includeDateFilterDataOnly: true,
        dateFilterDataURLParams: null,
        validStatus: [ true, false ],
        exportType: "user",
        protected: true,
        excludeActivityData: false
      },
      dataTableReady: true,
    };
  },

  mounted() {
    let vendorUser = this.$store.state.vendorUser;
    let dataToSend = {};

    if (vendorUser && vendorUser.roles && vendorUser.roles.includes("VENDOR") && !vendorUser.roles.includes("ADMIN")) {
      dataToSend.userId = vendorUser.userId
    }

    this.$setLoader();
    dataServiceVD.getVendorCodes(dataToSend).then((res) => {
      this.projectReferralCodes = res.data;
      this.$disableLoader();
    });
  },

  methods: {
    setProjectAndGetActivity(projectId) {
      this.$setLoader();

      dataServiceVD.fetchActivities({ projectId, unsorted: true }).then(r => {
        this.activities = r.data;
        this.dataToSend.activityStatuses = [ "I", "A", "T", "C" ]
        this.dataToSend.validStatus = [ true, false ]
        this.dataToSend.activityIds = r.data.map(a => a._id)
        this.dataToSend.exportType = "user"
        this.$disableLoader();
      });
    },

    loadProjects(projectReferralCodes) {
      this.dataToSend.projectId = null
      this.dataToSend.activityIds = []

      if (projectReferralCodes.length > 0) {
        dataServiceVD.getProjectFromCode({ projectReferralCodes }).then(r => {
           /* eslint-disable-next-line */
          console.log(r.data)
          this.projects = r.data;
        });
      }
    },
  
    fetchUserActivity() {
      this.$setLoader();

      dataServiceVD.fetchUserActivities(this.dataToSend).then(r => {
        this.userActivity = r.data;
        this.$disableLoader();
        this.exportDisable = false
      });
    },

    exportUserActivityToFile() {
      this.dataToSend.email = this.$store.state.userProfile.email
      dataServiceVD.exportUsersByUserActivity(this.dataToSend)
      this.exportDisable = true
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },

    generateDateFilterURLParams() {
      if (this.dataToSend.includeDateFilterDataOnly) {
        if (this.filterStartDate && this.filterEndDate && this.dataToSend.startDate && this.dataToSend.endDate) {
          return `startDate=${this.formatDate(this.dataToSend.startDate)}&endDate=${this.formatDate(this.dataToSend.endDate)}`
        } else if (this.filterStartDate && !this.filterEndDate && this.dataToSend.startDate) {
          return `startDate=${this.formatDate(this.dataToSend.startDate)}`
        } else if (!this.filterStartDate && this.filterEndDate && this.dataToSend.endDate) {
          return `endDate=${this.formatDate(this.dataToSend.endDate)}`
        }
      }

      return null
    },

    generateEditUrl(url) {
      return (this.dataToSend.dateFilterDataURLParams == null) ? url : `${url}?${this.dataToSend.dateFilterDataURLParams}`
    },

    formatDate(date) {
      return new Date(date).toISOString().substring(0, 10)
    },

    dateDataWatcher() {
      this.dataTableReady = false
      this.dataToSend.dateFilterDataURLParams = this.generateDateFilterURLParams()

      this.$nextTick (() => { 
        this.dataTableReady = true;
      });
    },
  },

  watch: {
    "dataToSend.startDate": function() {
      this.dateDataWatcher()
    },

    "dataToSend.endDate": function() {
      this.dateDataWatcher()
    },

    "filterStartDate": function() {
      this.dateDataWatcher()
    },

    "filterEndDate": function() {
      this.dateDataWatcher()
    },
  }
};
</script>